<template>
    <section class="mb-4" :class="{'d-inline-flex' : inline }">
        <div class="row align-items-between">
            <label v-if="label"
                :for="'input-'+uid"
                class="col"
                :class="inline ? 'col-form-label col-auto pe-3' : 'form-label'">
                <span v-html="label"></span>
                <Required v-if="isRequired" />
            </label>
            <div class="col-auto">
                <slot name="info"></slot>
            </div>
        </div>
        <div :class="{'is-invalid': isRequired && v$.content.$error }, { 'col': inline }, { 'input-group': inputText }" >
            <input :id="'input-'+uid"
                :type="inputType"
                :value="content" @input="update"
                class="form-control"
                :class="{'is-invalid': isRequired && v$.content.$error }, { 'col': inline }"
                @blur="v$.content.$touch"
                :placeholder="placeholder ?? ''"
            >
            <span v-if="inputText" class="input-group-text">{{ inputText }}</span>
        </div>
        <div v-if="formText" class="form-text text-end" v-html="formText"></div>
        <div v-if="v$.content.$error && v$.content.$errors" class="invalid-feedback">
            <span v-for="error in v$.content.$errors">{{ error.$message }}</span>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

import { helpers, maxValue, minValue, requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

const props = defineProps<{
    content: number|string|null;
    type?: string;
    label?: string;
    isRequired?: boolean;
    placeholder?: string;
    formText?: string;
    inline?: boolean;
    inputText?: string;
}>();

const emit = defineEmits<{
    (event: 'update', value: number|string): void,
}>();

const uid = ref<string|null>(null);

onMounted(() => {
    uid.value = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

});

const update = (event) => {
    emit('update',event.target.value)
};

const inputType = props.type ?? 'text';

/**
* VALIDATION
*/
const rules = {
    content: {
        requiredIf: helpers.withMessage(`${props.label} is required`, requiredIf(() => props.isRequired)),
    },
};
const v$ = useVuelidate(rules, props);
/** END VALIDATION **/
</script>

